import themeSettings from '@/settings/theme-settings.js';

const { useDarkTheme, appThemeColor } = themeSettings;

const state = {
  useDarkTheme,
  appThemeColor
};

const mutations = {
  
};

export default {
  namespaced: true,
  state,
  mutations
};