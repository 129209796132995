// 教材管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/material',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '',
        associateMenu: 'material/index'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/material/index/list/list.vue'),
          meta: {
            title: ''
          },
        },
        {
          path: 'build',
          component: () => import('@/views/material/index/build/build.vue'),
          meta: {
            title: '教材建设'
          }
        },
        {
          path: 'task-build',
          component: () => import('@/views/material/index/task-build/task-build.vue'),
          meta: {
            title: '任务教材建设'
          }
        }
      ]
    }
  ]
};