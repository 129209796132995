// 账号管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/admin',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'assignment',
      component: NoLayout,
      meta: {
        title: ''
      },
      children: [
        {
          path: 'index',
          component: NoLayout,
          meta: {
            title: '',
            associateMenu: 'admin/assignment/index'
          },
          children: [
            {
              path: 'list',
              component: () => import('@/views/admin/assignment/index/list/list.vue'),
              meta: {
                title: ''
              }
            },
            {
              path: 'addedit',
              component: () => import('@/views/admin/assignment/index/list/addedit.vue'),
              meta: {
                title: '账号'
              },
            },
            {
              path: 'password',
              component: () => import('@/views/admin/assignment/index/list/password.vue'),
              meta: {
                title: '密码重置'
              }
            }
          ]
        }
      ]
    }
  ]
};