// 反馈列表
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/feedback',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
 
children: [
  {
    path: 'index',
    component: NoLayout,
    meta: {
      title: '反馈列表',
      associateMenu: 'feedback/index'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/feedback/index.vue'),
        meta: {
          title: ''
        }
      }
    ]
  }
]
    
 
};