import { reqParams } from '@/enumerators/http.js';

const { LOCAL_STORE_TOKEN_KEY } = reqParams;

let _token = null;
let _storage = null;

export function changeTokenLocalStorage(recordLogin) {
  switch (recordLogin) {
    case true:
      _storage = window.localStorage;
      break;
    case false:
      _storage = window.sessionStorage;
      break;
  }

  _storage = window.localStorage;
}

export function setToken(token) {
  
  _storage.setItem(LOCAL_STORE_TOKEN_KEY, token);
  _token = token;
};

export function getToken() {
  if (_token === null) {
    let token = window.localStorage.getItem(LOCAL_STORE_TOKEN_KEY);
    token || (token = window.sessionStorage.getItem(LOCAL_STORE_TOKEN_KEY));
    _token = token;
  }
  return _token;
};

export function removeToken() {
  window.localStorage.removeItem(LOCAL_STORE_TOKEN_KEY);
  window.sessionStorage.removeItem(LOCAL_STORE_TOKEN_KEY);
  _token = null;
};