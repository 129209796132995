// 习题库
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/exercises',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '',
        associateMenu: 'exercises/index'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/exercises/index/list/list.vue'),
          meta: {
            title: ''
          },
        },
        {
          path: 'add',
          component: () => import('@/views/exercises/index/add/add.vue'),
          meta: {
            title: '新增习题'
          },
        },
        {
          path: 'edit',
          component: () => import('@/views/exercises/index/add/add.vue'),
          meta: {
            title: '编辑习题'
          },
        },
        {
          path: 'homework-add',
          component: () => import('@/views/exercises/index/homework/add.vue'),
          meta: {
            title: '新增实训作业'
          },
        },
        {
          path: 'homework-edit',
          component: () => import('@/views/exercises/index/homework/add.vue'),
          meta: {
            title: '编辑实训作业'
          },
        }
      ]
    }
  ]
};