import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/knowledge',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '',
        associateMenu: 'knowledge/index'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/knowledge/index/list/list.vue'),
          meta: {
            title: ''
          },
        },
        {
          path: 'knowledge-task',
          component: () => import('@/views/knowledge/index/knowledge-task/knowledge-task.vue'),
          meta: {
            title: ''
          },
        },
      ]
    }
  ]
};