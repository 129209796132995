// 教学库
import { Layout } from '../constant-components.js';

export default {
  path: '/planning',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/planning/index/index.vue'),
      meta: {
        title: ''
      }
    }
  ]
};