import { Layout, ErrorPage404 } from './constant-components.js';
import { INDEX_PAGE_PATH, REDIRECT_PATH, REDIRECT_NAME } from '@/enumerators/route.js';
import fullPageRoute from './async-route-modules/full-page.js';

const rootRoute = {
  path: '/',
  redirect: INDEX_PAGE_PATH,
  meta: {
    title: 'Root'
  }
};

const loginRoute = {
  path: '/login',
  name: 'login',
  component: () => import('@/views/login/index.vue'),
  meta: {
    title: '登录'
  }
};

const redirectRoute = {
  path: REDIRECT_PATH,
  component: Layout,
  meta: {
    title: REDIRECT_NAME,
    hideBreadcrumb: true
  },
  children: [
    {
      path: `${REDIRECT_PATH}/:path(.*)`,
      name: REDIRECT_NAME,
      component: () => import('@/views/redirect/index.vue'),
      meta: {
        title: REDIRECT_NAME,
        hideBreadcrumb: true,
      }
    }
  ]
};

export const errorRoute = {
  path: '/:path(.*)*',
  component: Layout,
  meta: {
    title: 'Error Page',
    hideBreadcrumb: true
  },
  children: [
    {
      path: '/:path(.*)*',
      component: ErrorPage404,
      meta: {
        title: '404',
        hideBreadcrumb: true
      }
    }
  ]
};

export const constantRoutes = [
  rootRoute,
  loginRoute,
  fullPageRoute,
  redirectRoute
];