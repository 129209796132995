import { createApp } from "vue";
import Preapp from './components/Application/index.vue';
import App from "./App.vue";
import router, { initRouter } from "./router/index.js";
import store from "./store/index.js";
import 'vfonts/Lato.css'
import naive from 'naive-ui'

import clickoutsideDirective from '@/utils/clickoutside.js';

async function initApp() {
  const preapp = createApp(Preapp);
  preapp.mount('#preapp');

  const app = createApp(App);

  app.use(store);

  app.use(naive);

  initRouter(app);
  await router.isReady();
    
  app.mount('#app');

  app.directive('clickoutside', clickoutsideDirective);
}

initApp();