// 系统设置 - 管理菜单
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/system-power',
  redirect: '',
  component: Layout,
  meta: {
    title: '',
  },
  children: [
    {
      path: 'menus',
      component: NoLayout,
      meta: {
        title: '',
        associateMenu: 'system-power/menus'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/system-power/menus/list/list.vue'),
          meta: {
            title: ''
          }
        },
        {
          path: 'edit',
          component: () => import('@/views/system-power/menus/edit/edit.vue'),
          meta: {
            title: '管理端 - 菜单管理'
          }
        },
      ],
    },
    {
      path: 'roles',
      component: NoLayout,
      meta: {
        title: '',
        associateMenu: 'system-power/roles'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/system-power/roles/list/list.vue'),
          meta: {
            title: '角色管理列表'
          }
        },
        {
          path: 'add',
          component: () => import('@/views/system-power/roles/add/add.vue'),
          meta: {
            title: '角色管理'
          }
        }
      ]
    }
  ]
};